<template>
  <div class="container">
    <c-card title="Herramientas">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Herramienta</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tool in tools" :key="tool.id">
            <td>
              <img
                height="50"
                :src="tool.main_image_link"
                alt="Imagen de herramienta"
              />
            </td>
            <td>{{ tool.name }}</td>
            <td class="text-nowrap">
              <button
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                @click="edit(tool.marco_legal_especifico_id, tool.id)"
              >
                <i class="fa-solid fa-pen"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import { all } from "@/services/tools";
import { find as findMarcoLegalEspecifico } from "@/services/marcos_legales_especificos";

export default {
  components: { CCard },
  data() {
    return {
      tools: [],
    };
  },
  mounted() {
    this.getTools();
  },
  methods: {
    getTools() {
      all().then((response) => {
        this.tools = response.data;
      });
    },
    edit(marcoLegalEspecificoId, toolId) {
      findMarcoLegalEspecifico(marcoLegalEspecificoId).then((response) => {
        const marcoLegalGeneralId =
          response.data.marco_legal_general_version.marco_legal_general.id;
        const marcoLegalGeneralVersionId =
          response.data.marco_legal_general_version.id;
        window.location.href = `/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${marcoLegalEspecificoId}/herramientas?tool_id=${toolId}`;
      });
    },
  },
};
</script>
